import React from "react";
import MatchTx from "./matchtx";
import { useWallet } from '@solana/wallet-adapter-react';
import data from '../urls/urls.json';
function App() {
    var publicKey = useWallet().publicKey;
    var address = publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58();
    var handle = data[0].handle;
    var acc = data
        .filter(function (item) { return item.handle === handle; })
        .map(function (filtered) { return filtered.url; });
    var token = acc.length > 0 && acc[0]
        ? acc[0].split("tokenAcc=")[1]
        : "";
    return (React.createElement("div", null,
        React.createElement(MatchTx, { address: address, gumdrop: token })));
}
export default App;
